<h1>VOUCHERS</h1>

<form id="payment-form">
  <div class="grupo">
    <div class="form-group">
      <label for="name">
        Nombre Completo
      </label>
      <input id="name" name="name" required [formControl]="name" class="form-control">
    </div>

    <div class="form-group" style="margin-left: 5%;">
      <label for="email">
        Correo Electronico
      </label>
      <input id="email" name="email" required [formControl]="email" class="form-control">
    </div>
  </div>
  <div class="form-group">
    <label for="amount">
      Monto
    </label>
    <input id="amount" name="amount" required [formControl]="amount" class="form-control">
  </div>

  <!-- Used to display form errors. -->
  <div id="error-message" role="alert"></div>

  <button id="submit-button" (click)="confirm()">Generar Voucher</button>
</form>


<div class="imprimir" [ngClass]="{'s': voucher, 'h': !voucher}" id="imprimir">
  <h1 class="titulo">
    ¡Gracias! Solo falta un paso más:
  </h1>
  <div class="instrucciones">
    <h3>Instrucciones de pago en OXXO:</h3>
    <h3>1. Entregue el código de al cajero en cualquier OXXO para que lo escanee.</h3>
    <h3>2. Proporcione el pago en efectivo al cajero.</h3>
    <h3>3. Una vez completado el pago, guarde el recibo de su pago para sus archivos.</h3>
    <h3>4. Para cualquier duda o aclaración, contacte el comerciante.</h3>
  </div>
  <div class="montofecha">
    <h4><span>Monto:</span> {{oxxoamount | currency : 'USD' : 'symbol' : '1.0'}}</h4>
    <h4><span>Expira:</span> {{date | date: 'shortDate'}}</h4>
  </div>
  <div class="logo">
    <img src="../../../assets/oxxo.png" alt="" style="width: 20%; height: 100%;">
    <img src="../../../assets/smart.jpeg" alt="" style="width: 30%; height: 100%;">
  </div>
  <div class="codigo">
    <ngx-barcode [bc-value]="barcode" [bc-display-value]="true"></ngx-barcode>
  </div>
</div>
