import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
declare const Stripe: any;
let bc;
let od;
let oa;
import * as printJS from 'print-js';
import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  amount = new FormControl();
  name = new FormControl();
  email = new FormControl();
  barcode;
  oxxoamount;
  date;
  voucher = false;
  boton = true;
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {

  }

  printTest() {
    // console.log("QUE LA PUTAMADRE")
    printJS({
      printable: 'imprimir', //Id del elemento
      type: "html", //Tipo HTML a PDF
      targetStyles: ['*'] //PARA APLICAR LOS ESTILOS
    });
    this.amount.setValue("");
    this.name.setValue("");
    this.email.setValue("");
  }



  confirm() {
    const self = this
    this.dataService.postAny("oxxo", { amount: (this.amount.value + "00") }).subscribe(async data => {
      var stripe = Stripe("pk_live_6yIu9QAfG1dCyQNeSBw6m4n800k7bWyt5C", {
        betas: ['oxxo_pm_beta_1'],
        apiVersion: '2020-03-02; oxxo_beta=v1'
      }); //PROD
      // var stripe = Stripe("pk_test_AeLM3DjrqQxP1m8e1dvzklPx008HJnvb4x", { // TEST
      //   betas: ['oxxo_pm_beta_1'],
      //   apiVersion: '2020-03-02; oxxo_beta=v1'
      // });

      await stripe.confirmOxxoPayment(
        data.data.client_secret,
        {
          payment_method: {
            billing_details: {
              name: this.name.value,
              email: this.email.value
            },
          },
        }, { handleActions: false })
        .then(function (result) {
          self.voucher = true;
          var r = result.paymentIntent;
          self.oxxoamount = r.amount / 100
          self.date = new Date(r.next_action.display_oxxo_details.expires_after * 1000)
          self.barcode = r.next_action.display_oxxo_details.number
          setTimeout(() => {
            self.printTest();
            self.voucher = false;
          }, 1000);
        })

    })


  }
}
