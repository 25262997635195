import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  // url = "http://smartbacked-env.czbpksb4bg.us-east-2.elasticbeanstalk.com/api/";
  url = "https://smarteachingback.herokuapp.com/api/";
  // url = "http://localhost:3000/api/";
  constructor(
    private _http: HttpClient
  ) { }

  postAny(api, data) {
    return this._http
      .post(this.url + api, data)
      .pipe(map((res: any) => res));
  }
}
